import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

// ---------- Vue-Meta ---------- //
import Meta from 'vue-meta'

Vue.use(Meta, {
  keyName: 'head',
  refreshOnceOnNavigation: true
})

// ---------- Bootstrap ---------- //
import 'popper.js'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

// ---------- FontAwesome ---------- //
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/css/solid.min.css'
import '@fortawesome/fontawesome-free/css/fontawesome.min.css'
import '@fortawesome/fontawesome-free/css/regular.min.css'

// ---------- CSS ---------- //
import '../src/assets/stylesheet/app.css'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
